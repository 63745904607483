import '../styles/terms.css';

import { graphql } from 'gatsby';
import React from 'react';

import Footer from '../components/footer';
import PlanHeader from '../components/menu';
import SEO from '../components/seo';

const Accessibility = () => {
  return (
    <>
      <SEO
        title='Accessibility of Guidelines | Standards | Protocols'
        canonicalUrl='https://www.getmybubble.com/accessibility/'
        description='Get My Bubble guidelines, standards and protocols for website accessibility and are always available to rectify any complaints regarding accessibility to people with disabilities.'/>

      <div className="container-fluid" style={{ margin: 0, padding: 0 }}>
        <PlanHeader />

        <div className="company-info-container about-container">
          <h1 className="carrier-plan-heading about-heading" >Accessibility</h1>
        </div>

        <div className="wrapper">
          <div className="container">
            <div className="row">
              <div className="col-12 accessibility-padding">
                <p>As recently as January 2020, the World Health Organization estimated that there are more than one billion people living with disabilities, with over 100 million persons having significant difficulties in functioning - for more information, see <a className="accessibility-link" href="https://www.who.int/health-topics/disability" target="_blank" rel="noreferrer">www.who.int/health-topics/disability</a></p>

                <p>Many of these disabilities can affect access to information technologies. Additionally, the same individuals who run into barriers that impede access to information technologies could benefit most from using them.</p>

                <p>Given the importance of the internet, accessibility to the web is of critical importance to people with disabilities around the world, including people with auditory, cognitive, physical, neurological, and visual disabilities. It is likewise important to those with accessibility needs due to aging.</p>

                <p>Providing accessible web content can also benefit people who do not have disabilities but who are experiencing situational barriers. For example, when accessing the internet from devices with small screens or in low bandwidth situations, or when experiencing barriers due to language or literacy levels, accessibility solutions can also support improved access.</p>

                <p>Bubble Insurance Solutions, a wholly owned subsidiary of Bubble Technologies, Inc. (“Bubble”) is firmly committed a making our website and its contents freely and easily accessible and available to all. We constantly strive to take into account all generally recognized guidelines, standards and protocols for website accessibility and are always available to rectify any complaints regarding accessibility to people with disabilities.</p>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </> );
};

export default Accessibility;
